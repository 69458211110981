<template>
  <div>
    <ListTemplate>
      <template v-slot:title>
        <h1>Ankiety</h1>
      </template>
      <template v-slot:header>
        <div>
          <v-btn
            small
            color="primary"
            @click="openCreateModal"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:content>
        <QuestionnairesList
          :data="data"
          :loading="loading"
          :pagination="pagination"
          @fetchData="fetchData"
          @rowClick="setQuestionnaireToEdit"
          @remove="setCandidateToRemove"
          @edit="openShowModal"
        />
      </template>
    </ListTemplate>
    <QuestionnaireForm
      v-if="questionnaireFormId"
      :questionnaireId="questionnaireFormId"
      @close="closeQuestionnaireForm"
      @refreshList="fetchData"
    />
    <QuestionnaireShow
      v-if="questionnaireShowId"
      :id="questionnaireShowId"
      @close="closeQuestionnaireShow"
    />
    <ConfirmationDialog
      v-if="questionnaireToRemove"
      :value="!!questionnaireToRemove"
      :loading="questionnaireRemoveLoading"
      @confirm="deleteQuestionnaire"
      @deny="questionnaireToRemove = null"
    />
  </div>
</template>

<script>
import ListTemplate from "@/templates/ListTemplate";
import QuestionnairesList from "@/components/questionnaires/QuestionnairesList";
import {notify, notifyStandardError} from "@/services/notificationService";
import QuestionnaireForm from "@/components/questionnaires/QuestionnaireForm";
import {fetchQuestionnaires, removeQuestionnaire} from "@/services/questionnaireService";
import QuestionnaireShow from "@/components/questionnaires/QuestionnaireShow";
import ConfirmationDialog from "@/components/system/ConfirmationDialog";

export default {
  name: 'Questionnaires',
  components: {ConfirmationDialog, QuestionnaireShow, QuestionnaireForm, QuestionnairesList, ListTemplate},
  data: () => ({
    data: [],
    loading: false,
    pagination: {
      perPage: 10,
      totalElements: 0,
      page: 1
    },
    questionnaireFormId: null,
    questionnaireShowId: null,

    questionnaireToRemove: null,
    questionnaireRemoveLoading: false,
  }),
  methods: {
    setQuestionnaireToEdit({id}) {
      this.questionnaireFormId = id
    },
    setCandidateToRemove(id) {
      this.questionnaireToRemove = this.data.find(item => item.id === id)
    },
    async fetchData() {
      try {
        this.loading = true
        const {data, totalElements} = await fetchQuestionnaires(this.pagination)
        this.data = data
        this.pagination.totalElements = totalElements
      } catch (e) {
        notifyStandardError()
      } finally {
        this.loading = false
      }
    },
    openCreateModal() {
      this.questionnaireFormId = 'create'
    },
    closeQuestionnaireForm() {
      this.questionnaireFormId = null
    },
    async deleteQuestionnaire() {
      try {
        this.questionnaireRemoveLoading = true
        await removeQuestionnaire(this.questionnaireToRemove.id)
        this.questionnaireToRemove = null
        this.questionnaireRemoveLoading = false
        notify('success', 'Ankieta została usunięta')
        await this.fetchData()
      } catch (e) {
        if (e.response.status !== 500 && e.response.data.message) {
          notify('error', e.response.data.message)
        } else {
          notifyStandardError()
        }
      } finally {
        this.questionnaireRemoveLoading = false
      }
    },
    openShowModal(id) {
      this.questionnaireShowId = id
    },
    closeQuestionnaireShow() {
      this.questionnaireShowId = null
    },
  }
}
</script>

<style scoped>

</style>
