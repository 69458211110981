import axios from 'axios'
import {camelToSnakeCase, everyPropKey, snakeToCamelCase, staySelectedProps} from "@/utils/dataTransformation";
import {prepareList} from "@/services/apiService";

export const fetchQuestionnaires = async (pagination) => {
  const preparedPagination = everyPropKey(pagination, camelToSnakeCase)
  if (preparedPagination.sort_by) {
    preparedPagination.sort_by = camelToSnakeCase(preparedPagination.sort_by)
  }
  
  const {data} = await axios.post('polls', preparedPagination)
  
  const preparedData = prepareList(data.data, snakeToCamelCase, staySelectedProps)([
    'id',
    'name',
    'questionQuantity',
    'redtrainId'
  ], '')
  
  return {
    data: preparedData,
    totalElements: data.total
  }
}

export const fetchQuestionnaire = async (id) => {
  const {data} = await axios.get(`poll/${id}`)
  return {
    title: data.name,
    redtrainId: data.redtrain_id,
    questions: data.questions
  }
}

export const saveQuestionnaire = async ({title, redtrainId}, id) => {
  const method = id ? 'put' : 'post'
  const path = id ? `poll/${id}` : 'poll'
  
  await axios[method](path, {
    name: title,
    redtrain_id: redtrainId
  })
}

export const removeQuestionnaire = async (id) => {
  await axios.delete(`poll/${id}`)
}
