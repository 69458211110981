<template>
  <div>
    <Question
      v-for="(question, idx) in localQuestions"
      :key="question.id"
      :number="idx + 1"
      :id="question.id"
      :editMode="question.editable"
      :text="question.text"
      :redtrainId="question.redtrainId"
      :errorMessage="question.errorMessage"
      :loading="question.loading"
      @save="updateQuestion"
      @delete="setDeleteCandidate"
      @toggleEditableMode="toggleEditableMode"
    />
    <v-card class="mt-5">
      <v-container>
        <v-row dense>
            <v-col cols="12" sm="10" md="10">
              <v-form
                ref="form"
                class="row row--dense"
                @submit.prevent="saveQuestion"
              >
                <v-col cols="12" sm="7">
                  <v-text-field
                    v-model="newQuestion.text"
                    :label="`${questions.length + 1}. Pytanie`"
                    :rules="inputRules"
                    :error="newQuestion.errorMessage"
                    clearable
                  />
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field
                    v-model="newQuestion.redtrainId"
                    label="RedTrain Id"
                    clearable
                  />
                </v-col>
              </v-form>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="d-flex align-center justify-end">
              <v-btn
                color="success"
                small
                :loading="newQuestion.l"
                @click="saveQuestion"
              >
                Zapisz
              </v-btn>
            </v-col>
          </v-row>
      </v-container>
    </v-card>
      <ConfirmationDialog
        v-if="questionToRemove"
        :value="!!questionToRemove"
        :loading="questionToRemoveLoading"
        @confirm="deleteQuestion"
        @deny="questionToRemove = null"
      />
  </div>
</template>

<script>
import Question from "@/components/questionnaires/Questions/Question";
import {createQuestion, deleteQuestion, updateQuestion} from "@/services/questionsService";
import {notifyStandardError} from "@/services/notificationService";
import {required} from "@/utils/validationRules";
import ConfirmationDialog from "@/components/system/ConfirmationDialog";

export default {
  name: 'QuestionsFormList',
  components: {ConfirmationDialog, Question},
  props: {
    questions: {
      type: Array,
      required: true
    },
    questionnaireId: {
      type: [Number, String]
    }
  },
  data: () => ({
    questionToRemove: null,
    questionToRemoveLoading: false,
    inputRules: [required],
    newQuestion: {
      id: 'new',
      text: '',
      redtrainId: '',
      editable: true,
      errorMessage: null,
      loading: false
    },
    localQuestions: []
  }),
  watch: {
    questions: {
      handler(newQuestions) {
        this.localQuestions = newQuestions.map(({id, question, redtrain_id}) => {
          const candidate = this.localQuestions.find(item => item.id === id)
          if (candidate && (candidate.editable)) {
            return candidate
          }
          return {
            id,
            text: question,
            redtrainId: redtrain_id,
            editable: false,
            errorMessage: null
          }
        })
      },
      deep: true
    }
  },
  methods: {
    async saveQuestion() {
      try {
        if (this.$refs.form.validate()) {
          this.newQuestion.loading = true
          await createQuestion(this.questionnaireId, {
            question: this.newQuestion.text,
            redtrainId: this.newQuestion.redtrainId
          })
          this.refreshData()
          this.newQuestion.text = ''
          this.newQuestion.redtrainId = ''
          this.$refs.form.resetValidation()
        }
      } catch (e) {
        notifyStandardError()
      } finally {
        this.newQuestion.loading = false
      }
    },
    async updateQuestion(id, {text, redtrainId}) {
      try {
        this.setLoadingMode(id, true)
        await updateQuestion(id, {question: text, redtrainId})
        this.toggleEditableMode(id)
        this.refreshData()
      } catch (e) {
        notifyStandardError()
      } finally {
        this.setLoadingMode(id, false)
      }
    },
    setDeleteCandidate(questionId) {
      this.questionToRemove = {...this.localQuestions.find(item => item.id === questionId)}
    },
    async deleteQuestion() {
      try {
        this.questionToRemoveLoading = true
        await deleteQuestion(this.questionToRemove.id)
        this.refreshData()
        this.questionToRemove = null
      } catch (e) {
        notifyStandardError()
      } finally {
        this.questionToRemoveLoading = false
      }
    },
    refreshData() {
      this.$emit('fetchQuestions')
    },
    toggleEditableMode(id) {
      this.localQuestions = this.localQuestions.map(item => {
        if (item.id === id) {
          return {...item, editable: !item.editable}
        }
        return item
      })
    },
    setLoadingMode(id, value) {
      if (id) {
        this.localQuestions = this.localQuestions.map(item => {
          if (item?.id === id) {
            return {...item, loading: value}
          }
          return item
        })
      }
    }
  }
}
</script>

<style scoped>
.row > * {
  padding-left: 6px;
  padding-right: 6px;
}
</style>
